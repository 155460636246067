.overview-container {
  /* padding: 20px; */
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
}
.overview-col {
  width: 48%; /* Adjust for more width, 2 cards per row */
  margin-bottom: 20px;
 

}
.overview-row{
  /* margin: 5rem; */
  padding:  2rem 2rem;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* Heading Style */
.overview-heading {
  font-size: 2.5rem;
  /* margin-bottom: 4rem; */
  /* padding-top: 3rem; */
  /* color: rgb(35, 35, 97); */
  color: black;
  font-family:'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
}
/* .custom-margin-left .col {
  margin-left: 20px;
} */

/* Card Style */
.card10 {
  border-radius: 40px 0 40px 0; /* Top-left and bottom-right rounded */
  box-shadow: 0 4px 8px rgba(95, 91, 91, 0.1);
  background-color: #fff;
  position: relative;
  padding: 01rem 1.5rem; /* Add padding inside the card */
  text-align: start;
  transition: all 0.3s ease;
  /* max-width: 80%; */

}

.card10:hover {
  background-color: #e2e2e2; /* Change background color on hover */
  transform: scale(1.05); /* Slightly enlarge the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}



.card-icon {
  position: absolute;
  top: -10px; /* Move the icon 50px upwards */
  left: -10px;
  /* align-items: center; */
  transform: translateY(-40px); /* Moves the icon 50px upwards */
  /* background-color: #fff; */
  padding: 10px;
  /* border-radius: 40%; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.card-icon img {
  width: 10vw; 
  height: 10vh; 
  color: #000000;
  
}


/* Card Heading */
.card-heading {
  font-family:'Poppins', sans-serif;
  font-size: 1.3rem;
  color: #000000;
  font-weight: bold;
 /* Space below the icon */
  padding-top: 2rem;

}

/* Card Description */
.card-description {
  font-family:'Poppins', sans-serif;
  font-size: 14px;
  color: #000000;
  padding: 0rem 1rem;
}

@media (min-width: 1024px) {
  .overview-row {
    /* margin-top: 5rem; */
    padding:  2rem 2rem; /* Add margin for larger screens */
  }

}
@media (max-width: 768px) {
  
  .overview-heading{
    font-size: 2rem;
    margin-bottom: 1rem;
    
  }
  .overview-row{
    padding: 3rem 4rem 4rem 4rem;
  }

}

@media (max-width: 480px) {

  .overview-heading {
    font-size: 1.5rem; /* Larger font size for medium screens */
    margin-bottom: 1rem;
    /* padding-top: 4rem; */
  }
  
  .overview-row{
    padding:  2rem 2rem;
  }

  .overview-col {
    width: 100%; /* Adjust for more width, 2 cards per row */
    margin-bottom: 30px;
  }
  .card-icon{
    left: 20px;
  }

  .card-icon img {
    width: 13vw; 
    height: 13vh; 
    color: #000000;
    
  }
  .card-heading {
    font-size: 1.2rem;
    /* padding-top: 1rem; */
  }
  .card-description {
    font-size: 14px;
    font-weight: 400;
    padding: 0rem 0rem;
   
  }
}

