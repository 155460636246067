.privacy-text {
  margin-top: 1% !important;
}

.logo-img {
  width: 15rem;
  margin-top: 1rem;
}

.brand-logo {
  position: absolute;
  margin-left: -35px;
}

.network{
  width: 100%;
  height: 80vh;
}

.searchbox{
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  justify-content: center;
  right: 2.5vw;
}

.filter{
  background-color: #034772;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  height: 2.3rem;
  min-width: 15vw;
  font-size: 12px;
  &::placeholder {
    color: white;
    font-size: 14px; 
  }
}

.searchbutton{
  padding: 3px;
  margin-top: 4px;
  background-color: #034772;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  position: absolute;
  right: 3px;

}

@media (max-width: 480px) {
  .searchbutton{

  right: 12vw !important;
  }
}

@media (max-width: 768px) {
  .searchbutton{

  right: 25vw !important;
  }
  .filter{
    width: 50vw !important;
  }
}
@media (max-width: 760px) {
  .searchbutton{
  right: 12vw;
  }
  .filter{
    width: 50vw;
  }
}
@media (max-width: 850px) {
  .searchbutton{
  right: 25vw ;
  }
  .filter{
    width: 50vw;
  }
}
@media (max-width:930px){
  .searchbutton{
    right: 35vw;
  }
}

@media (max-width: 1100px) {
  .searchbox{
    right: 0;

  }
  .searchbutton{ 
  right: 30vw;
  }
  .filter{
    width: 60vh;
  }
}