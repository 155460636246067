.contact-us-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
    font-family:'Poppins', sans-serif;
    /* padding: 50px 0; */
    height: 90vh;
    width: 100%;
  }

  .fw-bold {
    font-size: 3rem;
  }
  
  .contact-email {
    font-size: 1.6rem;
    font-family:'Poppins', sans-serif;
    font-weight: 500;
    /* color: #0d6efd; */
    text-decoration: none;
  }
  
  .contact-email:hover {
    text-decoration: none
    ;
  }

  .text-center{
    /* display: flex; */
    /* justify-content: center; Center horizontally */
    /* align-items: center; */
  }
  .text-center p{
    font-size: 1.3rem;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    /* line-height: 23px; */
  }
  
  .social-icons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-icons p {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .social-icons-row {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }


  @media (max-width: 768px) {
    .contact-us-container {
        /* background: #f8f9fa; */
        font-family:'Poppins', sans-serif;
        padding: 1rem;
        /* height: 100vh;
        width: 100%; */
      }
      
    
      .fw-bold {
        font-size: 3rem;
      }
    
      .contact-email {
        font-size: 1.2rem; /* Adjust font size for mobile view */
      }
    
      .text-center p {
        font-size: 1rem; /* Adjust font size for mobile view */
      }
      
      
  }
  @media (max-width: 480px) {
    .contact-us-container {
        /* background: #f8f9fa; */
        font-family:'Poppins', sans-serif;
        padding: 1rem;
        /* height: 100vh;
        width: 100%; */
      }
      .text-center1{
        margin-bottom: 4rem;
      }
    
      .fw-bold {
        font-size: 2rem;
      }
    
      .contact-email {
        font-size: 1.2rem; /* Adjust font size for mobile view */
      }
    
      .text-center p {
        font-size: 1rem; /* Adjust font size for mobile view */
      }
  }
  