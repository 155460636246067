/* AnimationComponent.css */

/* .animation-container { */
  /* width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden; */
  /* display: flex;
      justify-content: center;
      align-items: center; */
  /* background-color: #f5f5f5; */
/* } */

/* .component {
      position: absolute;
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      background-color: #ffffff;
      color: #000;
    } */

.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease-in, opacity 500ms ease-in;
}
.slide-exit {
  transform: translateX(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}

.screen-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.pause-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1); /* Light grey overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.pause-animation {
  color: lightgrey; /* Light grey pause icon */
  font-size: 64px;
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
