/* Container to take full viewport */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%; /* Full screen height */
  width: 100%;
  background-color: #ffffff;
  /* padding: 2rem; */
}
/* Wrapper to maintain flex alignment */
.home-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  /* max-width: 75%; */
  /* width: 100%; */
  /* height: 100%; */
  /* gap: 3rem; */
}

/* Image Styling */
.home-us-image img {
  width: 80%;
  /* max-width: 600px; */
  height: auto;
  border-radius: 0.5rem;
  object-fit: cover;
}

/* Content Styling */
.home-us-content {
  /* max-width: 800px; */
  padding-left: 2rem;
  width: 80%;
  text-align: left;
}

.home-us-content h1 {
  /* font-weight: 900; */
  /* width: 80%; */
  /* max-width: 600px; */
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #000000;
  /* font-weight: 500; */
}

.home-us-content p {
  font-size: 1.2rem;
  font-weight: 400;
  /* width: 60%; */
  font-family: "Poppins", sans-serif;
  /* line-height: 1.6; */
  color: black;
  text-align: justify;
}

.explore-btn {
  display: block;
  margin: 20px auto 0; /* Center the button */
  padding: 10px 29px;
  font-size: 16px;
  font-weight: regular;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  /* transition: background 0.3s ease-in-out; */
}

/* .explore-btn:hover {
    background-color: #0056b3;
  } */

/* Responsive Design */

/* Mobile View */
@media (max-width: 768px) {
  .home-wrapper {
    flex-direction: column-reverse;
    text-align: center;
  }

  .home-us-content {
    width: 90%;
    margin-bottom: 2rem;
  }

  .home-us-content h1 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .home-us-content p {
    font-size: 1rem;
    font-weight: 400;
  }

  .home-us-image img {
    max-width: 75%;
    margin-bottom: 1rem;
  }
}

/* Tablet View */
@media (min-width: 1024px) {
  .home-wrapper {
    height: 90vh;
    max-width: 90%;
  }

  .home-us-content {
    /* font-size: 1rem; */
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    /* line-height: 1.6; */
    color: #000000;
    text-align: justify;
  }
  .home-us-content p {
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    width: 95%;
  }

  .home-us-content h1 {
    font-size: 2.5rem;
    width: 90%;
    font-weight: bold;
    /* font-weight: 400; */
    margin-bottom: 2rem;
  }
  .home-us-image img {
    width: 100%;
    /* margin-bottom: 1rem; */
  }
}

@media (max-width: 480px) {
  .home-us-content p {
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    line-height: 1.6;
    color: #000000;
    text-align: justify;
    font-weight: 400 ;
  }

  .home-us-content h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .explore-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}
