/* HamburgerMenu.css */

.menu-button {
    /* position: fixed;
    top: 0;
    right: 0; */
    background-color: rgba(255, 255, 255, 0.8); /* 80% opacity */
    border-radius: 5px;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1000; /* ensure the button is above other elements */
  }
  
  .menu-button:hover {
    background-color: #e2e0e0; /* 80% opacity */
  }
  
  .hamburger-icon {
    position: relative;
    width: 30px;
    height: 22px;
  }
  
  .hamburger-icon span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2.5px;
    background-color: rgb(16, 14, 14);
    transition: all 0.3s ease;
  }
  
  .hamburger-icon span:nth-child(1) {
    top: 0;
  }
  
  .hamburger-icon span:nth-child(2) {
    top: 10px;
  }
  
  .hamburger-icon span:nth-child(3) {
    top: 20px;
  }
  
  .menu-button.open .hamburger-icon span:nth-child(1) {
    transform: rotate(-45deg);
    top: 10px;
  }
  
  .menu-button.open .hamburger-icon span:nth-child(2) {
    opacity: 0;
  }
  
  .menu-button.open .hamburger-icon span:nth-child(3) {
    transform: rotate(45deg);
    top: 10px;
  }
  
  .menu-items {
    font-family: "Poppins", sans-serif;
    position: fixed;
    top: 80px; /* adjust as needed */
    right: 20px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #e2e0e0; /* 80% opacity */
    border-radius: 5px;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    z-index: 999; /* ensure the menu items are below the button but above other elements */
  }
  
  .menu-items.open {
    max-height: 500px; /* adjust as needed */
    /* padding: 10px 12px; */
    
    /* margin-right: 20px; */
  }
  
  .menu-item {
    color: rgb(0, 0, 0);
    /* text-align: center; */
    padding: 13px 28px;
    text-decoration: none;
  }
  /* .menu-item a:hover {
    color: #ddd; /* change color on hover, optional */
  

  .menu-item a {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  
  /* .menu-item a:hover {
    color: #ddd; 
  } */
  
  /* .menu-item a:visited {
    color: white; 
  } */

  /* body {
    overflow: auto;
  }
  
  html {
    scroll-behavior: smooth; /* Uncomment this line to enable smooth scrolling */
  /* }  */