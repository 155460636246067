/* @import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Aldo+the+Apache&display=swap"); */

.checkbox-container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.checkbox-label {
  font-size: 0.9rem;
  color: #333;
  display: flex;
  align-items: center;
}

.checkbox-label input {
  margin-right: 0.5rem;
}

.blur {
  filter: blur(2px);
  pointer-events: none; /* Prevent clicks */
}

.scanner-container {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 25vh; */
  height: 90vh; /* Fullscreen height */
  padding-bottom: 15vh; /* Add padding to ensure space around the content */
  box-sizing: border-box;
  background-color: #ffffff;
  transition: filter 0.3s ease; /* Smooth transition for the blur effect */
  position: relative;
}

.footer {
  position: absolute;
  bottom: 10px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  /* font-size: 14px; */
  color: #333;
}

.content {
  flex-grow: 1; /* Pushes the footer down */
}

.logo-container {
  position: absolute;
  left: 2%;
  top: 2%;
}

/* .mainRmlogo {
} */

/* .title {
  font-family: "Orbitron", sans-serif;
  font-size: 22px;
  color: #075283;
  text-align: center;
  margin: 20px;
  letter-spacing: 0.05rem;
} */
.home-title {
  /* font-family: "Poppins", sans-serif; */
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.25rem;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.search-box {
  display: flex;
  justify-content: center;
  color: grey;
  width: 100%;
  max-width: 700px;
  background-color: #f4f4f4;
  border-radius: 30px;
  padding: 0px 15px;
  margin: 10px 5px;
  box-sizing: border-box;
}

.search-box input {
  flex: 1;
  padding: 0.7rem;
  border: none;
  outline: none;
  border-radius: 18px;
  /* font-family: "Poppins", sans-serif; */
  font-size: 18px;
  background-color: #f4f4f4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.search-box input::placeholder {
  /* font-family: "Poppins", sans-serif; */
  color: grey;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 10px;
  color: grey;
}
.icon2 {
  background: rgba(128, 128, 128, 0.2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  padding: 0px 3px;
  color: grey;
  stroke-width: 1.5;
  height: 2rem;
  margin-top: 9px;
}

.icon3 {
  background: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  padding: 0px 3px;
  color: white;
  stroke-width: 1.5;
  height: 2.5rem;
  margin-top: 5px;
}

.divp {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  /* font-family:"Poppins",sans-serif; */
  color: grey;
  text-align: center;
}
.link {
  text-decoration-line: underline;
  color: black;
  text-decoration-thickness: 0.5px;
}
.error {
  height: 25px;
  margin: 0.5rem;
  align-items: center;
  text-align: center;
}

.error-message {
  /* font-family:"Poppins",sans-serif; */
  color: red;
  font-size: 0.875rem;
  margin-top: 6px;
}

@media (max-width: 768px) {
  .scanner-container {
    padding: 20px 10px; /* Add padding to avoid content touching edges */
  }

  .home-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .search-box {
    width: 95%; /* Adjust search box width for smaller screens */
    display: flex;
  }

  .search-box input::placeholder {
    font-size: 14px;
  }

  .search-button {
    padding: 8px 16px;
  }
}

@media (max-width: 1100px) {
  .search-box {
    width: 85%; /* Full width for search box on very small screens */
  }
}

/* @media (max-width: 480px) {
  
} */

@media (max-width: 480px) {
  .search-box {
    width: 95%; /* Full width for search box on very small screens */
  }

  .scanner-container {
    padding: 20px 5px; /* Further padding for very small screens */
    /* width: fit-content; */
  }

  .home-title {
    font-size: 0.9rem;
    margin: 5px;
  }

  .divp {
    font-size: 0.8rem;
    padding: 10px;
  }

  .search-box {
    width: 100%; /* Full width for search box on very small screens */
  }

  .search-box input::placeholder {
    font-size: 12px;
  }

  .search-button {
    padding: 8px 14px;
  }
}
