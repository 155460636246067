/* Container to take full viewport */
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full screen height */
  width: 100%;
  background-color: #ffffff;
  padding: 2rem;
}
/* Wrapper to maintain flex alignment */
.about-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  /* max-width: 75%; */
  /* width: 100%; */
  /* height: 100%; */
  /* gap: 3rem; */
}

/* Image Styling */
.about-us-image img {
  width: 80%;
  /* max-width: 500px; */
  height: auto;
  border-radius: 0.5rem;
  object-fit: cover;
}

/* Content Styling */
.about-us-content {
  max-width: 600px;
  text-align: left;
}

.about-us-content h2 {
  font-weight: 400;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
  color: #000000;
  font-weight: 500;
}

.about-us-content p {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  /* line-height: 1.6; */
  color: black;
  text-align: justify;
}

/* Responsive Design */

/* Mobile View */
@media (max-width: 768px) {
  .about-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .about-us-content h2 {
    /* font-size: 2rem; */
    font-weight: bold;
    text-align: center;
  }

  .about-us-content p {
    font-size: 1rem;
    font-weight: 400;
  }

  .about-us-image img {
    max-width: 60%;
    margin-bottom: 1rem;
  }
}

/* Tablet View */
@media (min-width: 1024px) {
  .about-wrapper {
    height: 100vh;
    /* max-width: 90%; */
  }

  .about-us-content p {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    /* line-height: 1.6; */
    color: #000000;
    text-align: justify;
  }
  .about-us-content {
    /* text-align: center; */
    /* max-width: 45%; */
  }

  .about-us-content h2 {
    font-size: 2.5rem;
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .about-us-content p {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    /* line-height: 1.6; */
    color: #000000;
    text-align: justify;
  }

  .about-us-content h2 {
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold;
  }
}
