.map{
    margin-top: -12%;
    margin-right: 5%;
}
@media (max-width: 1500px){
    .map{
        margin-top: -12%;
        margin-right: 0%;
    }
}
@media (max-width: 1100px){
    .map{
        margin-top: -20%;
        margin-right: 0%;
    }
}
@media (max-width:480px){
    .map{
        margin-top:-25%;
    }
    
}
@media (max-width:1200px){
    .map{
        margin-top:-10%;
    }
    @media (max-width:1100px){
        .map{
            margin-top:-13%;
        }
    }
        
}