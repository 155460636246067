.onboardingNavbar {
  padding: 10px;
  padding-left: 2%;
  display: flex;
  width: 25vh;
}

.logorm {
  height: 50px;
}

.verticalLine {
  border-left: 2px solid grey;
  height: 40px;
  margin: 5px 10px 0 10px;
}
/* .verticalLine1 {
  border-left: 2px solid grey;
  max-height: 80vh;
  margin: 5px 10px 0 10px;

  @media (max-height:780px) {
    
  }
} */

.verticalLine1 {
  border-left: 2px solid grey; /* Default vertical line */
  max-height: 80vh;
  margin: 5px 10px 0 10px;
}

/* Responsive for mobile view */
@media (max-width: 1100px) {
  .verticalLine1 {
    border-left: none; /* Remove vertical line */
    border-top: 2px solid grey; /* Add horizontal line */
    width: 100%; /* Full width for horizontal line */
    height: 2px; /* Set line height to 2px */
    margin: 5px 0; /* Adjust margin for horizontal line */
  }
}

.verticalLine2 {
  border-left: 2px solid grey;
  min-height: 15vh;
/* ======= */
/* >>>>>>> 390ea762b24dd556155c3ed2afb7b17624fbe22f
  margin: 5px 10px 0 10px;*/
}



  /* @media (max-height:780px) {
    .verticalLine1{
    width: 100vh;
    height: 2px;
    margin: 5px 10px 0 10px;
    border-left: 2px solid grey;

  }
} */
.traceRoute
{
  min-width:50vh;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;

}

.loading-spinner1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Fullscreen height */
  flex-direction: column;
  color:#034772 !important;
}

.spinner1 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.Searchbox{
  height:30vh;
  max-width: 70vw;
}

