/* Container and Header Styling */
.table-container {
  padding: 1rem; 
 background-color: rgb(255, 255, 255);
  text-align: center;
  display: flex;
  font-family:'Poppins', sans-serif;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  /* margin-bottom: 50px; */
}

/* Table Layout for Bootstrap 5 */
.table-heading {
  font-size: 2.5rem;
  margin-bottom: 4rem;
  font-family:'Poppins', sans-serif;
  color: #000000;
  font-weight: bold;
  align-items: center;
  align-self: center;
  /* margin-top: 10px; */
}

/* Card Layout */
.cardC {
  border-radius: 13px;
  border: none;
  box-shadow: 0px 0px 60px 10px #64636333 ;
  /* background-color: #ffffff; */
  padding: 20px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  max-width: 100%;
}

.cardC:hover {
  background-color: #e2e2e2;
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Header Section - Icon & Title in One Row */
.cardC-header {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Icon Styling */
.cardC-icon {
  width: 70px;
  height: 70px;
}

/* Card Heading */
.cardC-heading {
  font-size: 24px;
  font-family:'Poppins', sans-serif;
  color: #000000;
  font-weight: bold;
  text-align: start;
}

/* Card Description */
.cardC-description {
  font-size: 14px;
  font-family:'Poppins', sans-serif;
  color: #000000;
  margin-top: 10px;
  /* padding: 10px; */
  text-align: center;
}

.text-center {
  align-content: center;
}

/* Image Styling */
.chimg {
  /* width: 100%; */
  /* height: auto; */
  /* margin: 0;
  padding: 0; */
  object-fit: cover;
  /* margin-top: 120px;  */
  max-width: 100%;  /*Ensure it doesn't stretch*/
}

/* Media Queries for responsiveness */

/* For medium screens (tablets and below) */
@media (min-width: 1024px) {
  .table-container {
    height: 100vh;
    width: 100%;
  }

}

@media (max-width: 1024px) {
  .cardC-heading {
    font-size: 18px;
  }

  .cardC-description {
    font-size: 13px;
  }

  .chimg {
    max-width: 88;
   
  }

  .table-heading {
    font-size: 32px;
  }

  .cardC {
    width: 100%;
  }

  /* Ensure columns stack properly */
  .col-md-4 {
    flex: 1 0 100%;
    margin-bottom: 20px;
  }
}

/* For smaller screens (smartphones and below) */
@media (max-width: 768px) {
  
  .cardC-heading {
    font-size: 1.3rem;
  }

  .cardC-description {
    font-size: 14px;
    margin-top: 10px;
  }

  .chimg {
    max-width: 80%;
    /* height: 300px;  */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .table-heading {
    font-size: 28px;
  }

  .cardC {
    width: 100%;
  }

  /* Stack cards and image vertically */
  .col-md-4 {
    flex: 1 0 100%;
    margin-bottom: 0px;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .cardC-heading {
    font-size: 1.3rem;
  }

  .cardC-description {
    font-size: 12px;
    /* padding-top: 30px; */
  }

  .cardC-icon{
    width: 50px;
    height: 50px;
  }

  /* .chimg {
    max-width: 100%;
    height: 400px; 
  } */

  .table-heading {
    /* font-size: 24px; */
    margin-bottom: 2rem;
  }

  .cardC {
    width: 100%;
  }

  /* Stack cards and image vertically */
  .col-md-4 {
    flex: 1 0 100%;
    margin-bottom: 20px;
  }
}

