.navbar {
    padding: 10px;
    padding-left: 2%;
    display: flex;
  }
  
  .logo {
    height: 45px;
  }
  
  .vertical {
    border-left: 2px solid grey;
    height: 40px;
    margin: 5px 10px 0 10px;
  }
  
