.network {
  width: 100%;
  height: 50vh !important;
  font-family: "Orbitron";
}
@media (max-width: 480px) {
  .network {
    width: 100%;
    height: 50vh;
  }
}
@media (max-width: 768px) {
  .network {
    width: 100%;
    height: 50vh;
  }
}
